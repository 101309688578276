import {
  AfterContentChecked,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { lista } from './modelo/lista';
import { ControlValue } from '../../utiles/ControlValue';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BotonComponent } from '../boton/boton.component';
import { AlertasSwalService } from '../../../../../apps/destinux/src/app/core/utils/alertas/swal/alertas-swal.service';
import { EIcono } from '../../../../../apps/destinux/src/app/core/enums/alertas/swal';
import { Icon } from '../../iconos/iconos';
import { environment } from '@destinux-components/environment';
import { EnumsLib } from '../../utiles/enums';
import { EEstadoBoton } from '../boton/models/enums';

@Component({
  selector: 'destinux-seleccion-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    ListboxModule,
    FormsModule,
    BotonComponent,
  ],
  templateUrl: './seleccion-modal.component.html',
  styleUrls: ['./seleccion-modal.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeleccionModalComponent),
      multi: true,
    },
  ],
})
export class SeleccionModalComponent
  extends ControlValue
  implements OnInit, AfterContentChecked
{
  //#region viewChild
  @ViewChild('dialogo') dialogo!: ElementRef;
  //#endregion

  //#region inputs
  @Input({ required: true }) objeto: lista[] = [];
  @Input() maxViajero: number = 1;
  @Input() mismaEmpresa: boolean = true;
  //#endregion

  //#region outputs
  @Output() anyadir: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region variables
  public visible: boolean = false;
  public estado: EEstadoBoton = EEstadoBoton.desactivado;
  public objetoFiltrado: lista[] = [];
  public Icon = Icon;

  public enums = EnumsLib;
  //#endregion

  //#region hostbinding
  @HostBinding('style.--viajero') viajero = `url(${environment.recursos}${environment.baseIconosOriginales}usuario_viajero/icono_viajero.svg)`;
  //#endregion
  
  //#region constructor
  constructor(private _alerta: AlertasSwalService) {
    super();
  }
  //#endregion

  //#region onInit
  ngOnInit(): void {
    this.objetoFiltrado = [...this.objeto];
    console.log(this.maxViajero);
  }
  //#endregion

  ngAfterContentChecked(): void {
    if (this.value) {
      this.estado = EEstadoBoton.activado;
      this.hndBusqueda();
      this.ponerFocoInput();
    }
  }

  //#region metodos
  verificaMaxViajero(): void{
    if(this.value.length >= this.maxViajero){
      this.objetoFiltrado.forEach(x => {
        if(!this.value.some((y: lista) => y.id == x.id)){
          x.disabled = true;
        }
      });
    }
    else{
      this.objetoFiltrado.forEach(x => x.disabled = false);
    }
  }

  private ponerFocoInput():void{
    const input = document.querySelector('p-dialog.modal-seleccion p-listbox input') as HTMLInputElement; 
    if(input) input.focus();
  }
  //#endregion

  //#region metodos usados en el html
  hndBusqueda(): void {
    this.verificaMaxViajero();
    const ultimo =
      this.value !== undefined ? this.value[this.value.length - 1] : undefined;
    this.objetoFiltrado = [...this.objeto];

    if (this.value) {
      this.value.forEach((v: any) => {
        this.objetoFiltrado = this.objetoFiltrado.filter((y) => {
          if (
            y.objeto.NumIdentificacion.toUpperCase().includes(
              v.objeto.NumIdentificacion.toUpperCase()
            ) &&
            y.index != v.index
          ) {
            return false;
          } else {
            return true;
          }
        });
      });
    }

    if (ultimo !== undefined) {
      this.objetoFiltrado = this.objetoFiltrado.filter((x) => {
        if (this.value.length > 0 && this.mismaEmpresa) {
          if (
            JSON.stringify(ultimo.objeto.ParteEmpresa) ==
            JSON.stringify(x.objeto.ParteEmpresa)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }
  }

  hndShow() {
    this.visible = true;
  }

  hndChangeEstado() {
    if (this.value.length > 0) {
      this.estado = EEstadoBoton.activado;
    } else {
      this.estado = EEstadoBoton.desactivado;
    }
    this.hndBusqueda();
  }

  hndAnyadir() {
    this.visible = false;
    if (this.value.length > this.maxViajero) {
      this._alerta.alerta(
        'Alerta',
        EIcono.Warning,
        'Solo puede añadir ' +
          this.maxViajero +
          ' viajero(s) y se han seleccionado ' +
          this.value.length +
          ' viajero(s)',
        undefined,
        undefined,
        'OK'
      );
    } else {
      this.anyadir.emit(true);
    }
  }
  //#endregion
}
