<div class="g-heigth-80 g-width-80 g-height-128@md g-width-128@md">
    <destinux-boton alturaBoton="100%" anchuraBoton="100%" (click)="hndShow()" [iconoStart]="Icon.AgregarViajero" 
    [modelo]="enums.EModeloBoton.icono" [redondeo]="enums.EBorderRadiusBoton.rectangular" [talla]="enums.ETallaBoton.xg"></destinux-boton>
</div>
<p-dialog class="modal modal-seleccion" [showHeader]="false" [dismissableMask]="true" [closable]="true" [modal]="true" position="center" [(visible)]="visible" [style]="{ width: '90vw' }">

    <p-listbox [options]="objetoFiltrado" [(ngModel)]="value" optionDisabled="disabled" optionLabel="nombre" [virtualScroll]="true" [filter]="true"
        [virtualScrollItemSize]="43" [multiple]="true" [checkbox]="true" [showToggleAll]="false" (ngModelChange)="EscribirValor(); hndChangeEstado();"
        [metaKeySelection]="false" [listStyle]="{'max-height': '220px'}">
        <ng-template let-obj pTemplate="item">
            <div [innerHTML]="obj.objeto?.ramaEstilizada" id="select"></div>
        </ng-template>
    </p-listbox>
    <br>
    <destinux-boton class="g-flex g-flex--justify-content-center" (click)="hndAnyadir()" [color]="enums.EColorBoton.secundario" 
        texto="añadir a los viajeros seleccionados" [estado]="estado" [iconoStart]="Icon.Viajero"></destinux-boton>
    
</p-dialog>